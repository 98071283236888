import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './managementAndReporting.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
// import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";
import Text from "../../shared/Text/Text";
import {getRoutes} from "../../shared/routes";
import SubpageLink2 from "../../shared/InternalLinks/SubpageLink2";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import LinkButton from "../../shared/LinkButton/LinkButton";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_management_and_reporting"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const ManagementAndReporting = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    // const title = routes.Traceability.sections.forest.title.replace(/& /g, "&\u00a0");
    const title = routes.TraceabilityForest.pageTitle;
    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'50%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'forest.png', 'forest')}
                    </div>
                    {/*<PhoneFrameVideo
                        videoSrcURL={MarkingVideo}
                        placeholderImage={'Marking_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />*/}
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'45%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text textIsBold={false}>
                    Forest plots of land creation by setting the geo-location polygon data.
                    <br/> <br/>
                    Display the selected areas on the Web Client Interface screen and view available information and
                    data created by the timber log authentication and verification system functionality.
                    <br/> <br/>
                    Use satellite images preview in selected
                    timeframes for deforestation analysis.
                </Text>
                <br/>
                <LinkButton
                    label={'Learn more'}
                    route={routes.TraceabilityForest}
                />
            </SectionTextWrapper>
        </div>
    )
};

export default ManagementAndReporting;
