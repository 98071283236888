import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from "../components/shared/Footer/Footer";
import {getRoutes} from '../components/shared/routes';
import Measurement from "../components/sections/measurement/Measurement";
import Inventory from "../components/sections/inventory/Inventory";
import ManagementAndReporting from "../components/sections/managementAndReporting/ManagementAndReporting";
import Logistic from "../components/sections/logistic/Logistic";
import AdditionalInformation
    from "../components/shared/additionalInformation/additionalInformation";
import TopSectionNavigation from "../components/shared/TopSectionNavigation/TopSectionNavigation";
import MeasurementImg from "../images/sections/forestryNavCards/Forestry_Hero_Measurement.png"
import MarkingImg from "../images/sections/forestryNavCards/Forestry_Hero_Marking.png"
import InventoryImg from "../images/sections/forestryNavCards/Forestry_Hero_Inventory.png"
import LogisticImg from "../images/sections/forestryNavCards/Forestry_Hero_Logistic.png"
import ManagementAndReportingImg from "../images/sections/forestryNavCards/Forestry_Hero_ManagementAndReporting.png"
import PageTitle from "../components/shared/PageTitle/PageTitle";
import SmallFlowDiagram from "../components/sections/SmallFlowDiagram/SmallFlowDiagram";
import ForestStreet from "../components/sections/ForestStreet/ForestStreet";
import SawmillTrader from "../components/sections/sawmillTrader/SawmillTrader";
import AtTheSawmill from "../components/sections/AtTheSawmill/AtTheSawmill";

const Traceability = () => {
    const routes = getRoutes().Traceability;
    const pageTitle = routes.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <PageTitle>{routes.pageTitle}</PageTitle>

            <SmallFlowDiagram/>

            <ManagementAndReporting/>

            <ForestStreet/>

            <Logistic/>

            <SawmillTrader />

            <AtTheSawmill />

            {/*<Measurement/>*/}

            {/*<Marking/>*/}

            {/*<Inventory/>*/}

            <AdditionalInformation/>

            <Footer/>

        </Layout>
    );
};

export default Traceability;
