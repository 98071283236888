import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './topSectionNavigation.module.scss';
import ArrowRight from '../../../images/icons/arrow_small_right_green.svg';

const TopSectionNavigation = props => {
    const {t} = useTranslation();

    const {navCards = []} = props;

    const renderSection = (index, imgScale = 1, imageAlt, text, image, link) => {
        return (
            <div className={[css.section, css[`section${index}`]].join(' ')} key={`section${index}`}>
                <div className={css.sectionImageWrapper}>
                    <img src={image} alt={imageAlt} style={{transform: `scale(${imgScale})`}}/>
                </div>
                <div className={css.sectionText}>
                    <div className={css.sectionTextInner}>
                        {text}
                    </div>
                    <div className={css.sectionLinkWrapper}>
                        <a href={`#${link}`} className={css.link1}><span className={css.linkText}>Learn more</span>{" "}
                            <span className={css.arrow}><img src={ArrowRight} alt=""/></span></a>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={css.wrapper}>
            <div className={css.sectionsWrapper}>
                {navCards.map((card, index) => renderSection(index, 1, '', card.title, card.image, card.link))}
            </div>
        </div>
    );
};

export default TopSectionNavigation;
