import React from "react";
import css from './atTheSawmill.module.scss';
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import ListItem from "../../shared/ListItem/ListItem";
import AtTheSawmillVideo from "./AtTheSawmill.mp4";
import LinkButton from "../../shared/LinkButton/LinkButton";
import {getRoutes} from "../../shared/routes";
import VideoWithPreloadedImage from "../../shared/VideoWithPreloadedImage/VideoWithPreloadedImage";
import {getImagesFluid} from "../../componentsUtils";
import {graphql, useStaticQuery} from "gatsby";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_at_the_sawmill"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 500, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const AtTheSawmill = () => {
    const routes = getRoutes();
    const title = routes.TraceabilityAtTheSawmill.pageTitle;
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <VideoWithPreloadedImage
                    videoSrcURL={AtTheSawmillVideo}
                    placeholderImage={'at_the_sawmill.jpg'}
                    placeholderImageAlt={'at the sawmill'}
                    imagesFluid={imagesFluid}
                />
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    <div>
                        <ListItem
                            list={[
                                'stock turn over time controls',
                                'segregation of non-compliant logs',
                                'verification of Dcode marked logs selected for production',
                                'provenance confirmation of a single log',
                                'provenance transfer to a board level at the production',
                                'final products, boards with provenance data information',
                                'EUDR DDS creation'
                            ]}/>
                    </div>
                </div>
                <LinkButton
                    label={'Learn more'}
                    route={routes.TraceabilityAtTheSawmill}
                />
            </SectionTextWrapper>
        </div>
    )
};

export default AtTheSawmill;
