import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './inventory.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";
import InventoryVideo from "./Inventory_Video.mp4";
import Text from "../../shared/Text/Text";
import {getRoutes} from "../../shared/routes";
import SubpageLink2 from "../../shared/InternalLinks/SubpageLink2";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_inventory"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Inventory = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    const title = routes.Traceability.sections.inventory.title;
    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'50%'}>
                <div className={css.imageVideoWrapper}>
                    <PhoneFrameVideo
                        videoSrcURL={InventoryVideo}
                        placeholderImage={'Inventory_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'inventory.png', 'inventory')}
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'50%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text>
                    Defining in <SubpageLink2 link={routes.Solutions.route}
                                              section={routes.Solutions.sections.mobileApps.anchor}> the
                    mobile app</SubpageLink2> the log length, the ring width data are already stored,
                    inventory calculation is provided.
                    <br/><br/>
                    Reporting available on the <SubpageLink2 link={routes.Solutions.route}
                                                             section={routes.Solutions.sections.saas.anchor}>TimberID
                    client</SubpageLink2> web interface business intelligence.
                    <br/><br/>
                    Inventory data of woodpiles on the primary platform, forest street and/or the truckloads
                    are using the stored single log data by matching the selected quantity.
                </Text>
            </SectionTextWrapper>
        </div>
    )
};

export default Inventory;
