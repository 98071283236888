import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './logistic.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
// import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";
import Text from "../../shared/Text/Text";
import {getRoutes} from "../../shared/routes";
import SubpageLink2 from "../../shared/InternalLinks/SubpageLink2";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import ListItem from "../../shared/ListItem/ListItem";
import LinkButton from "../../shared/LinkButton/LinkButton";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_logistic"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Logistic = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    const title = routes.Traceability.sections.logistic.title;
    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'logistic.png', 'logistic')}
                    </div>
                    {/*<PhoneFrameVideo
                        videoSrcURL={MarkingVideo}
                        placeholderImage={'Marking_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />*/}
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'48%'} isBoldText={false}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <div className={css.text}>
                    Digital Delivery Note creation
                    <br/><br/>
                    <div>
                        <ListItem
                            list={[
                                'logistics provider data',
                                'vehicle registration number, container number',
                                'GPS record track data record, if available',
                                'apply at least one log marking based on Dcode',
                                'set of images of the loaded vehicle, container'
                            ]}/>
                    </div>
                </div>
                <LinkButton
                    label={'Learn more'}
                    route={routes.TraceabilityLogistics}
                />
            </SectionTextWrapper>
        </div>
    )
};

export default Logistic;
